<template>
  <en-table-layout :tableData="tableData.data" :loading="loading" @selection-change="handleSelectionChange">
    <template slot="toolbar">
      <div class="col">
        <div class="toolbar-title">操作日志</div>
      </div>
      <!-- <div class="col-auto">
        <el-button size="small" @click="GET_AccountLog">刷新</el-button>
        <el-button size="small" @click="handleRemove" type="primary">批量删除</el-button>
      </div> -->
    </template>

    <template slot="table-columns">
      <!-- 编号 -->
      <!-- <el-table-column align="center" type="selection" /> -->
      <!-- 操作者 -->
      <el-table-column prop="account_name" label="操作者" width="150" />
      <!-- 操作日期 -->
      <el-table-column label="操作日期" width="150">
        <template slot-scope="scope">{{ scope.row.create_date | unixToDate }}</template>
      </el-table-column>
      <!-- IP地址 -->
      <el-table-column prop="ip" label="IP地址" width="150" />
      <!-- 操作记录 -->
      <el-table-column prop="oper_detail" label="操作记录" min-width="350" />
    </template>

    <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
      @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-sizes="MixinPageSizes"
      :layout="MixinTableLayout" :page-size="tableData.page_size" :total="tableData.data_total">
    </el-pagination>
  </en-table-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import * as API_SiteMenu from '@/api/siteMenu';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  name: 'loginJournal',
  components: { EnTableLayout },
  computed: {
    ...mapGetters(['user'])
  },
  data () {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表数据 */
      tableData: {},

      /** 分页 */
      page_no: 1,
      page_size: 20,
      data_total: '',

      /** 选中数据 */
      multipleSelection: []
    }
  },
  mounted () {
    this.GET_AccountLog();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.page_size = size
      this.GET_AccountLog()
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.page_no = page
      this.GET_AccountLog()
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    /** 获取操作日志列表 */
    GET_AccountLog () {
      this.loading = true;
      /** 列表参数 */
      let params = {
        page_no: this.page_no,
        page_size: this.page_size,
        account_id: this.user.uid,   // 账户id
        account_type: 1, // 账户类型 0:前端会员 1:商家 2：供应商 3：企业，4大平台
        type: 2,         // 日志类型，1登录日志，2操作日志，3其他日志
      };
      // 如果是企业跳站点（0 代表企业登录   1代表企业跳转商家），设置成 3
      this.MixinParentEnterpriseInfo().then(res => {
        if (res.parentLogin) {
          params.account_type = 3;
        }
        API_SiteMenu.getAccountLog(params).then(res => {
          this.loading = false;
          this.tableData = res;
        }).catch(() => {
          this.loading = false
        })
      })
    },
    //批量删除
    handleRemove () {
      let _multipleSelection = this.multipleSelection;
      if (_multipleSelection.length == 0) {
        this.$message.error('请至少选择1条要删除的信息');
        return;
      }
      /** 列表参数 */
      let _id = _multipleSelection.map(content => content.id);
      API_SiteMenu.deleteAccountLogById(_id).then(res => {
        this.$message.success('删除成功');
        setTimeout(() => {
          this.GET_AccountLog();
        }, 2000)
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
