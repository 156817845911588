/**
 * 导航栏相关API
 */

import request from '@/utils/request'

/**
 * 获取导航菜单列表
 * @param params
 */
export function getSiteMenuList(params) {
  return request({
    url: 'seller/pages/site-navigations',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 添加导航菜单
 * @param client_type
 * @param params
 */
export function addSiteMenu(client_type = 'PC', params) {
  return request({
    url: 'seller/pages/site-navigations',
    method: 'post',
    data: {
      ...params,
      client_type
    }
  })
}

/**
 * 显示隐藏
 * @param client_type
 * @param params
 */
export function changeDisplay(data) {
  return request({
    url: `/seller/pages/site-navigations/changeDisplay/${data.id}`,
    method: 'post',
    data
  })
}

/**
 * 获取导航菜单详情
 * @param id
 */
export function getSiteMenuDetail(id) {
  return request({
    url: `seller/pages/site-navigations/${id}`,
    method: 'get'
  })
}

/**
 * 修改导航菜单
 * @param id
 * @param parmas
 */
export function editSiteMenu(id, parmas) {
  return request({
    url: `seller/pages/site-navigations/${id}`,
    method: 'put',
    data: parmas
  })
}

/**
 * 删除导航菜单
 * @param id
 */
export function deleteSiteMenu(id) {
  return request({
    url: `seller/pages/site-navigations/${id}`,
    method: 'delete'
  })
}

/**
 * 导航排序
 * @param id
 * @param sort_type [up|down]
 */
export function sortSiteMenu(id, sort_type) {
  return request({
    url: `seller/pages/site-navigations/${id}/${sort_type}`,
    method: 'put'
  })
}

/**
 * 查询账户日志列表
 *
 */
export function getAccountLog(params) {
  return request({
    url: `/log/accountLog`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 *  查询账户日志详情
 *
 */
export function getAccountLogById(id) {
  return request({
    url: `/log/accountLog/${id}`,
    method: 'get',
    loading: false
  })
}

/**
 * 删除账户日志列表
 *
 */
export function deleteAccountLogById(id) {
  return request({
    url: `/log/accountLog/${id}`,
    method: 'delete',
    loading: false
  })
}

/**
 * 删除账户日志列表
 *
 */
export function deleteBatch(ids) {
  return request({
    url: `/log/accountLog/${ids}/delete`,
    method: 'delete',
    loading: false
  })
}

/**
 * 获取企业等级列表
 * @returns {Promise<any>}
 */
export function getAllList() {
  return request({
    url: '/seller/shops/shopLv/getAllList',
    method: 'get',
  })
}


/**
 * 获取企业续费信息
 */
export function getRenewInfo() {
  return request({
    url: 'seller/shops/shopLv/enterpriseRenewal',
    method: 'get'
  })
}
